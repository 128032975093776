@use 'common' as common;

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300 650;
    font-display: swap;
    src: url('https://cdn.nav.no/aksel/fonts/SourceSans3-normal.woff2') format('woff2');
}

/* cyrillic */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300 650;
    font-display: swap;
    src: url('https://cdn.nav.no/aksel/fonts/SourceSans3-normal-cyrillic.woff2') format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

:root {
    --text-fluid-12-14: clamp(0.75rem, 0.7083rem + 0.2083vi, 0.875rem);
    --text-fluid-14-16: clamp(0.875rem, 0.8333rem + 0.2083vi, 1rem);
    --text-fluid-16-18: clamp(1rem, 0.9583rem + 0.2083vi, 1.125rem);
    --text-fluid-18-20: clamp(1.125rem, 1.0833rem + 0.2083vi, 1.25rem);
    --text-fluid-20-22: clamp(1.25rem, 1.2065rem + 0.2174vi, 1.375rem);
    --text-fluid-22-24: clamp(1.375rem, 1.3333rem + 0.2083vi, 1.5rem);
    --text-fluid-24-28: clamp(1.5rem, 1.4167rem + 0.4167vi, 1.75rem);
    --text-fluid-32-36: clamp(2rem, 1.9167rem + 0.4167vi, 2.25rem);
    --text-fluid-36-48: clamp(2.25rem, 2rem + 1.25vi, 3rem);
    --default-action-color: var(--a-blue-600);
    --brand-purple-deep: #99185e;
    --brand-purple-light: #e6c7d8;
    --brand-beige: #fef5ef;
}

.page__page-with-side-menus,
.page__single-col-page {
    .navds-body-long--small,
    .navds-body-short--small {
        font-size: var(--text-fluid-16-18);
    }

    .navds-body-long--medium,
    .navds-body-short--medium,
    .navds-read-more__button,
    .navds-expansioncard__header-content,
    li::marker,
    .navds-label {
        font-size: var(--text-fluid-18-20);
    }

    li::marker,
    .navds-label {
        font-weight: 500;
    }

    .navds-accordion__header-content.navds-heading--small {
        font-size: var(--text-fluid-18-20);
    }

    .navds-body-long--large,
    .navds-body-short--large {
        font-size: var(--text-fluid-20-22);
    }
    .navds-body-long--small,
    .navds-body-long--medium,
    .navds-body-long--large {
        line-height: 1.65;
        margin-bottom: var(--a-spacing-9);
    }

    .navds-heading {
        line-height: 1.3;
    }
    .navds-heading--xsmall,
    .navds-heading--small,
    .navds-heading--medium {
        margin-bottom: var(--a-spacing-2);
    }
    .navds-heading--xsmall {
        font-size: var(--text-fluid-18-20);
    }
    .navds-heading--small {
        font-size: var(--text-fluid-18-20);
        font-weight: 650;
    }
    .navds-heading--medium {
        font-size: var(--text-fluid-22-24);
        font-weight: 650;
    }
    .navds-heading--large {
        font-size: var(--text-fluid-32-36);
        font-weight: 650;
    }
    .navds-heading--xlarge {
        font-size: var(--text-fluid-36-48);
        font-weight: 650;
    }

    strong {
        font-weight: 650;
    }
}
